.hero-main {
    text-align: center;
    background-image: url(../images/profile/bg.jpg);
    background-color: var(--secondary-color);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 22rem;
}

.hero-sub {
    text-align: center;
    font-weight: bold;
    font-size: 50px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 6.1rem auto;
}

.hero-sub i {
    font-size: 3rem;
}

body {
    font-family: var(--main-font-family);
    background-color: var(--primary-color);
    color: white;
}

h1,
h2,
h3 {
    text-align: center;
}

.hero-main h1 {
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.3rem;
}

.hero-main h2 {
    font-weight: bold;
    font-size: 1.5rem;
}

.hero-main h3 {
    font-family: var(--sub-font-family);
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 60%;
    margin: auto;
    line-height: 1.5rem;
}

.hero-sub h1 {
    font-weight: bold;
}

.hero-sub h2 {
    font-family: var(--sub-font-family);
    font-weight: bold;
    font-size: 1.2rem;
}

.home-content-heading {
    font-weight: bold;
    font-size: 2.5rem;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    padding-top: 6.2rem;
}
