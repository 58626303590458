.navbar-toggler {
    color: white;
}

.navbar {
    padding: 0px;
}

.navbar-toggler {
    margin-right: 0.7rem;
}

.nav-con-main {
    font-size: 1rem;
    font-weight: 500;
    padding-top: 3.1rem;
    padding-bottom: 3rem;
    background-color: var(--secondary-color);
}

.nav-item {
    padding-left: 20px;
}

.navigator {
    color: var(--default-text-color);
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
}

.navigator-text {
    text-align: right !important;
    text-transform: uppercase;
    flex-grow: 0;
    margin-right: 8.7rem;
}

.navigator-icon {
    margin-left: 8.7rem;
}

.nav-link:hover {
    background-color: var(--link-hover-color);
}
