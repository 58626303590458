.footer-icon-col {
    letter-spacing: 1rem;
    margin: 1% auto;
}

.footer-icon-col a:hover {
    color: var(--link-hover-color);
}

.footer-icon {
    font-size: 2.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.bottom_nav {
    background-color: var(--extra-color);
    padding: 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
}

.footer-icon {
    text-decoration: none;
    color: var(--default-text-color);
    font-size: 2rem;
    margin: 1rem;
}

.text-link {
    color: var(--default-text-color);
}

.text-link:hover {
    text-decoration: none;
    color: var(--default-text-color);
}

.default-text {
    color: var(--default-text-color);
}
