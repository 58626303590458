.icons_main_page {
    text-decoration: none;
    color: var(--default-text-color);
    font-size: 2rem;
    margin: 1rem;
}

.about-title {
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    color: #f65282;
}

.about-paragraph {
    font-weight: 400;
    line-height: 1.6rem;
    font-family: var(--sub-font-family);
    margin: 1rem 1rem auto 1rem;
    padding: auto;
}

.about-container {
    background-color: var(--extra-color);
}

.aboutimg-thumbnail,
.main-img-thumbnail {
    width: 80%;
    height: 80%;
}

.profile-image {
    display: flex;
    margin: 10% auto;
}

.profile-name {
    font-family: var(--main-font-family);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3rem;
    letter-spacing: 0.2rem;
    text-align: left;
}

.profile-name-sm {
    font-family: var(--main-font-family);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3rem;
    letter-spacing: 0.2rem;
    text-align: center;
}
