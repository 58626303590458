.contact-page {
    font-family: var(--sub-font-family);
}

.contact-page h3 {
    margin: 20px auto;
    letter-spacing: 0.1em;
}

.contact-page p {
    font-size: 20px;
    margin: 10px auto;
}

.contact-page img {
    margin-bottom: 2rem;
}
